


























import { debounceProcess } from "@/helpers/debounce";
import { useCoa } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import { AccountingAccountResponseDto } from "@/models/interface/AccountingAccount.interface";
import { Component, Prop, Vue } from "vue-property-decorator";

/**
 * @deprecated
 * @duplicate
 *
 * use {@linkcode src/components/custom/select/SelectAccount}
 */

@Component
export default class SelectAccount extends Vue {
  @Prop({ required: true, type: String, default: "" })
  value!: string;

  options: Option<AccountingAccountResponseDto>[] = [];
  loading = false;

  created(): void {
    this.onSearch = debounceProcess(this.onSearch, 500);

    this.fetchAccounts(new RequestQueryParams());
  }

  onChange(value: string | null): void {
    this.$emit("input", value || "");
    this.$emit("change", value || "");
    this.$emit("update:meta", this.findOptions(value || ""));
  }

  fetchAccounts(params: RequestQueryParams): void {
    const { findAllChildAccount } = useCoa();
    params.sorts = "code:asc";
    this.loading = true;
    findAllChildAccount(params)
      .then((res: Pagination<AccountingAccountResponseDto>) => {
        this.options = res.data.map<Option<AccountingAccountResponseDto>>(
          item => ({
            label: item.code + " - " + item.description,
            value: item.code,
            key: item.id,
            meta: item,
          })
        );
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onSearch(value = ""): void {
    const { filterBy } = useCoa();
    const params = new RequestQueryParams();
    params.search = filterBy({
      code: value,
      description: value,
    });

    this.fetchAccounts(params);
  }

  findOptions(value: string): Option<AccountingAccountResponseDto> | undefined {
    const option = this.options.find(e => e.value === value);
    return option;
  }
}
